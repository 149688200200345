import React from "react"
import { Link } from "gatsby"
import Button from "@bit/azheng.joshua-tree.button"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TheaterVideo from "@components/Theater/TheaterVideo"
import ImageMeta from "../components/ImageMeta"
const wisdomTeethData = require("../_archives/procedures/wisdom-teeth.json")

import "../css/pages/w4w-22.scss"
import ImageSlider from "../components/ImageSlider/ImageSlider"

const W4W = () => {
  const recipients = ["AOS/DEV/w4w-22-recipient-charlotte"]
  const image = name => `/AOS/DEV/${name}.png`
  return (
    <Layout pageTitle="wisdom-page" language={"en"}>
      <SEO
        lang="en"
        title="Wisdom For Wisdom | Alfi Oral, Dental Implant & Facial Surgery"
        description="Wisdom For Wisdom provides a Houston-area student with free wisdom teeth removal. Learn about our Wisdom For Wisdom recipient."
      />
      <div className="w4w-22">
        <div className="w4w-22__hero">
          <img
            src={image("w4w-top-left-corner-image")}
            className="w4w-22__hero-tl w4w-22__shapes-img"
            alt="color shapes"
          />
          <img
            src={image("2022-w4w-logo")}
            className="w4w-22__hero-middle"
            alt="wisdom for wisdom logo"
          />
          <img
            src={image("w4w-top-right-corner-image")}
            className="w4w-22__hero-tr w4w-22__shapes-img"
            alt="colorful shapes"
          />
        </div>

        <div className="w4w-22__about">
          <div className="w4w-22__about-content">
            <h1>Saving for College? We Want to Help!</h1>
            <h2>We’re making college more affordable for a local student.</h2>
            <p>
              Our 2022 Wisdom For Wisdom program helps to offset some of the
              costs of college by providing free{" "}
              <Link
                to="/oral-surgery-procedures/wisdom-teeth-removal-houston-tx/"
                title="Learn more about wisdom teeth removal">
                wisdom teeth removal
              </Link>{" "}
              to a student in the Houston area.
            </p>
            <p>
              This year our recipient is Charlotte, a college student from
              Houston who currently attends the University of Michigan in Ann
              Arbor, where she is pursuing a degree in theater design and
              production. In her application, she wrote about how the long
              nights of rehearsals, working two jobs, and discomfort from her
              wisdom teeth growing in are causing her lack of sleep.
            </p>
            <p>
              We hope that not only are we able to help Charlotte get more sleep
              by relieving the discomfort and pain caused by her wisdom teeth,
              but also to relieve some of the financial burden of college,
              allowing her to save for other educational expenses.
            </p>
            <p>
              We wish every student the best as they pursue their academic
              endeavors.
            </p>
          </div>
        </div>

        <div className="w4w-22__apply">
          <img
            src={image("w4w-mid-left-image")}
            className="w4w-22__apply-tl w4w-22__shapes-img"
            alt="color shapes"
          />
          <div className="w4w-22__apply-content">
            <h3>
              Follow Charlotte as she has her wisdom teeth removed at our
              Houston office on{" "}
              <a
                href="https://www.facebook.com/dr.davidalfi"
                title="Visit us on Facebook"
                target="_blank">
                Facebook
              </a>{" "}
              and{" "}
              <a
                href="https://www.instagram.com/dr.davidalfi/"
                title="Visit us on Instagram"
                target="_blank">
                Instagram
              </a>
              .
            </h3>
            <br />
            <p>
              <i>
                Learn more about{" "}
                <Link
                  to="/oral-surgery-procedures/wisdom-teeth-removal-houston-tx/"
                  title="Learn more about wisdom teeth removal">
                  wisdom teeth removal
                </Link>
                .
              </i>
            </p>
            <div className="w4w-22__facts">
              <h3>Just the facts...</h3>
              <ul>
                <li>
                  Wisdom teeth are the only teeth that don’t form before birth.
                </li>
                <li>
                  85% of wisdom teeth will need to be removed at some point.
                </li>
                <li>
                  Wisdom teeth are the last of the 32 adult permanent teeth to
                  develop and erupt.
                </li>
                <li>
                  Wisdom teeth that are impacted (they cannot erupt completely)
                  can cause problems in your mouth and sinus.
                </li>
                <li>
                  Wisdom teeth often appear between the ages of 17 and 25.
                </li>
              </ul>
            </div>
          </div>
          <img
            src={image("w4w-bottom-right-image")}
            className="w4w-22__apply-br w4w-22__shapes-img"
            alt="color shapes"
          />
        </div>
      </div>
      <div className="w4w-22__recipient-img">
        <ImageMeta
          cloudName="nuvolum"
          publicId={recipients[0]}
          width="auto"
          responsive
          responsiveUseBreakpoints="true"
        />
      </div>
    </Layout>
  )
}

export default W4W
